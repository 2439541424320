import { Component, inject } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterOutlet } from '@angular/router';
import { SeoService } from './modules/shared/services/seo.service';
import {MetricsServiceService} from "./modules/shared/services/analytics/metrics-service.service";

@Component({
  selector: 'app-root',
  standalone: true,
  imports: [CommonModule, RouterOutlet],
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent {
  title = 'yesport-front';
  constructor() {
    inject(SeoService).init();
    inject(MetricsServiceService).init();
  }

}
